import axios from "axios";

const api = axios.create({
  baseURL:
    "https://786t41c7wh.execute-api.ap-northeast-2.amazonaws.com/dotshop/",
  credentials: "include",
  params: {
    lptag: "AF9130333",
    subid: "joongomarket",
  },
});

export const CoupangApi = {
  getProduct: (keyword) =>
    api.get("coupang", {
      params: {
        keyword: keyword,
      },
    }),
  getFashionProducts: async () => {
    // 패션 카테고리 중 랜덤하게 하나 선택
    const keywords = ["여자 패션", "유아 패션"];
    const randomKeyword = keywords[Math.floor(Math.random() * keywords.length)];
    
    try {
      const response = await api.get("coupang", {
        params: {
          keyword: randomKeyword,
        },
      });
      
      return response;
    } catch (err) {
      console.error(err);
      return { data: { body: [] } };
    }
  },
  getJoongo: (keyword, latitude, longitude) =>
    api.get("joongo", {
      params: {
        keyword: keyword,
        latitude: latitude,
        longitude: longitude,
      },
    }),
  searchKeyword: (keyword) =>
    api.get("search", {
      params: {
        keyword: keyword,
      },
    }),
};
