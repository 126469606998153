/* eslint-disable no-multi-str */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loading from "Components/Loading";
import { CoupangApi } from "api";
import Product from "Components/Product";
import Joongo from "Components/Joongo";
import CheckFooter from "./Footer";
import KeywordList from "./KeywordList";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { KakaoLinkDefault } from "react-kakao-link";
import { Button } from "@material-ui/core";
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { crossOriginResourcePolicy } from "helmet";
import { datadogRum } from "@datadog/browser-rum";
import DisplayAds from "./DisplayAds";

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 0.5%;
`;
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginRight: 0,
    marginLeft: 20,
    marginTop: 5,
    overflow: "visible",
    // paddingTop: 10,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Header = styled.div`
  display: flex;
  width: 90%;
  padding-top: 10px;
  justify-content: center;
  padding-left: 5%;
  align-items: center;
  @media only screen and (max-width: 750px) {
    padding-top: 0;
    display: inline-block;
    width: 95%;
    justify-content: flex-start;
    padding-left: 0;
    align-items: center;
  }
`;
const HeaderImg = styled.img`
  height: 52px;
  flex: 1;
  padding-top: 6%;
  @media only screen and (max-width: 750px) {
    padding-top: 1%;
    padding-bottom: 4%;
    height: 32px;
  }
`;

const A = styled.a`
  text-decoration-line: none;
  /* text-decoration: underline; */
  text-decoration-color: #4d5159;
`;

const Link = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const APP = styled.div`
  color: #4d5159;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
    padding-bottom: 17%;
  }
`;

const SNS = styled.div`
  color: #4d5159;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }
`;

const Form = styled.form`
  flex: 4;
  display: flex;
  align-items: center;
  margin: 0 1% 0 4%;
  height: 48px;
  @media only screen and (max-width: 750px) {
    flex: 4;
    height: 32px;
    padding-bottom: 20px;
  }
`;

const JoongoContainer = styled.div`
  // margin-top: 14%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 0;
  @media only screen and (max-width: 770px) {
    // margin-top: 130px;
    // margin-top: 58%;
    padding-left: 0px;
    flex-wrap: wrap;
    width: 100%;
    display: block;
  }
  @media only screen and (min-width: 780px) {
    // margin-top: 130px;
    // margin-top: 22%;
    width: 90%;
    margin-left: 10%;
  }
`;

const TXT = styled.div`
  box-sizing: border-box;
  font-size: 19px;
  width: 100%;
  border-bottom: 0.1rem solid;
  border-color: #d7d2ce;
  padding: 0.5% 0 0.5% 2%;
  @media only screen and (max-width: 750px) {
    padding: 1.5% 0 1.5% 1%;
    font-size: 11px;
  }
`;

const SUBTXT = styled.div`
  box-sizing: border-box;
  font-size: 19px;
  color: #00792f;
  width: 90%;
  border-bottom: 0.2rem solid;
  font-weight: bold;
  border-color: #00792f;
  padding: 0.5% 0 0.5% 1%;
  @media only screen and (max-width: 750px) {
    // padding: 3% 0 0.5% 1%;
    width: 100%;
    font-size: 15px;
  }
`;

const Fixed = styled.div`
  position: sticky;
  width: 102%;
  background-color: white;
  // padding-top: 8px;
  margin-left: -1%;
  top: 0px;
  top: 0;
  left: 0;
  z-index: 0;
`;
const SUBTXTContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CoupangContainer = styled.div`
  // padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  @media only screen and (max-width: 750px) {
    padding-left: 0px;
  }
  @media only screen and (min-width: 750px) {
    justify-content: center;
  }
`;

const CoupangInside = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

function mergeArrays(arr1, arr2, n1, n2, result_merge) {
  let i = 0,
    j = 0,
    k = 0;
  while (i < n1 && j < n2) {
    // arr1[i].type = "new";
    // arr2[j].type = "used";
    result_merge[k++] = arr2[j++];
    result_merge[k++] = arr1[i++];
  }
  // Store remaining elements of first array
  while (i < n1) result_merge[k++] = arr1[i++];
  // Store remaining elements of second array
  while (j < n2) result_merge[k++] = arr2[j++];
  // return result_merge;
  return result_merge;
}

const FindProduct = (keyword, regionCheck, price) => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  async function findProduct() {
    setLoading(true);
    if (regionCheck.checked == true) {
      await navigator.geolocation.getCurrentPosition(
        async function (position) {
          try {
            await axios
              .all([
                //쿠팡 장애 주석
                CoupangApi.getProduct(keyword),
                CoupangApi.getJoongo(
                  keyword,
                  position.coords.latitude,
                  position.coords.longitude
                ),
              ])
              .then(
                axios.spread((...responses) => {
                  const {
                    data: { body: data },
                  } = responses[0];
                  // 쿠팡 장애 주석
                  const {
                    data: { body: data2 },
                  } = responses[1];
                  if (typeof data === "undefined") {
                    setData([]);
                  } else {
                    setData(data);
                  }
                  if (typeof data2 === "undefined") {
                    setData2([]);
                  } else {
                    setData2(data2);
                  }
                  datadogRum.setGlobalContextProperty("j_api", {
                    results: responses[0],
                  });
                  datadogRum.setGlobalContextProperty("c_api", {
                    results: responses[1],
                  });
                  if (typeof data !== "undefined") {
                    let result_merge = [];
                    result_merge = mergeArrays(
                      data,
                      data2,
                      data.length,
                      data2.length,
                      result_merge,
                      price
                    );
                    setResult(result_merge);
                  } else {
                    setResult([]);
                  }
                })
              );
          } catch (err) {
            await axios
              .all([
                CoupangApi.getProduct(keyword),
                // 쿠팡 장애 주석
                CoupangApi.getJoongo(
                  keyword,
                  position.coords.latitude,
                  position.coords.longitude
                ),
              ])
              .then(
                axios.spread((...responses) => {
                  const {
                    data: { body: data },
                  } = responses[0];

                  //쿠팡 장애 주석
                  // const {
                  //   data: { body: data2 },
                  // } = responses[1];
                  datadogRum.setGlobalContextProperty("j_api", {
                    results: responses[0],
                  });
                  if (typeof data === "undefined") {
                    setData([]);
                  } else {
                    setData(data);
                  }
                  if (typeof data2 === "undefined") {
                    setData2([]);
                  } else {
                    setData2(data2);
                  }
                  console.log(data);
                  console.log(data2);
                  let result_merge = [];
                  result_merge = mergeArrays(
                    data,
                    data2,
                    data.length,
                    data2.length,
                    result_merge,
                    price
                  );
                  console.log(result_merge);
                  setResult(result_merge);
                })
              );
          } finally {
            setLoading(false);
          }
        },
        async function (error) {
          toast.error(<>{"앱의 위치 권한 및 GPS를 확인해주세요."}</>, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        },
        {
          enableHighAccuracy: false,
          timeout: 3000,
        }
      );
    } else {
      try {
        setLoading(true);
        await axios
          .all([
            // 쿠팡 장애 주석
            CoupangApi.getProduct(keyword),
            CoupangApi.getJoongo(keyword, "", ""),
          ])
          .then(
            axios.spread((...responses) => {
              const {
                data: { body: data },
              } = responses[0];
              //쿠팡 장애 주석
              const {
                data: { body: data2 },
              } = responses[1];

              if (typeof data === "undefined") {
                setData([]);
              } else {
                setData(data);
              }
              if (typeof data2 === "undefined") {
                setData2([]);
              } else {
                setData2(data2);
              }

              if (typeof data !== "undefined") {
                // let result_merge = Array(data.length).fill(0);
                datadogRum.setGlobalContextProperty("j_api", {
                  results: responses[0],
                });
                datadogRum.setGlobalContextProperty("c_api", {
                  results: responses[1],
                });
                let result_merge = [];
                result_merge = mergeArrays(
                  data,
                  data2,
                  data.length,
                  data2.length,
                  result_merge,
                  price
                );
                setResult(result_merge);
              } else {
                setResult([]);
              }
            })
          );
      } catch (err) {
        console.error(err);
        await axios
          .all([
            // 쿠팡 장애 주석
            CoupangApi.getProduct(keyword),
            CoupangApi.getJoongo(keyword, "", ""),
          ])
          .then(
            axios.spread((...responses) => {
              const {
                data: { body: data },
              } = responses[0];
              //쿠팡 장애 주석
              // const {
              //   data: { body: data2 },
              // } = responses[1];
              if (typeof data === "undefined") {
                setData([]);
              } else {
                setData(data);
              }

              if (typeof data2 === "undefined") {
                setData2([]);
              } else {
                setData2(data2);
              }
              datadogRum.setGlobalContextProperty("j_api", {
                results: responses[0],
              });
              if (typeof data !== "undefined") {
                // let result_merge = Array(data.length).fill(0);
                let result_merge = [];
                result_merge = mergeArrays(
                  data,
                  data2,
                  data.length,
                  data2.length,
                  result_merge,
                  price
                );
                setResult(result_merge);
              } else {
                setResult([]);
              }
            })
          );
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    findProduct();
  }, [keyword, regionCheck]);
  return { result, loading };
};

const Main = ({ match, location }) => {
  const PROBABILITY = {
    FASHION: 50,    // 패션 상품 확률
    ADS: 50,        // 광고 확률 (나머지)
  };

  const getRandomContent = () => {
    const random = Math.random() * 100;
    
    if (random < PROBABILITY.FASHION) {
      return "fashion";
    } else {
      return "ads";  // 광고 표시
    }
  };

  const contentType = getRandomContent();
  const [showFashion] = useState(contentType === "fashion");
  
  const tenpingAds = [
    {
      id: "54908",
      name: "창고대방출",
      href: "https://link.coupang.com/a/XXx3w",
      imgSrc: "https://img3a.coupangcdn.com/image/affiliate/event/promotion/2022/03/17/b940f5e81570000801a78a5f99866e4f.png"
    },
  ];

  const generateAdHtml = (ad) => {
    return `<div><div><div style="width:100%; max-width:800px;"> \
      <a href="${ad.href}" target="_blank"> \
      <img src="${ad.imgSrc}" style="width:100%; height:auto; border-radius:0;" alt=""> \
      </a></div><div style="">`;
  };

  const googleDisplayAd = `
    <div id="google-ad-container">
      <script>
        try {
          // 기존 광고 스크립트 제거
          const existingScript = document.querySelector('script[src*="pagead2.googlesyndication.com"]');
          if (existingScript) {
            existingScript.remove();
          }

          // 새 광고 스크립트 추가
          const adScript = document.createElement('script');
          adScript.async = true;
          adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7116765104034349';
          adScript.crossOrigin = 'anonymous';
          document.head.appendChild(adScript);

          // 광고 로드
          adScript.onload = function() {
            try {
              (adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
              // 구글 광고 로드 실패 시 tenping 광고로 대체
              const container = document.getElementById('google-ad-container');
              const tenpingAd = document.createElement('div');
              const randomIndex = Math.floor(Math.random() * ${tenpingAds.length});
              tenpingAd.innerHTML = \`${generateAdHtml(tenpingAds[0])}\`;
              container.innerHTML = '';
              container.appendChild(tenpingAd);
            }
          };
        } catch (e) {
          // 스크립트 로드 실패 시 tenping 광고로 대체
          const container = document.getElementById('google-ad-container');
          const tenpingAd = document.createElement('div');
          const randomIndex = Math.floor(Math.random() * ${tenpingAds.length});
          tenpingAd.innerHTML = \`${generateAdHtml(tenpingAds[0])}\`;
          container.innerHTML = '';
          container.appendChild(tenpingAd);
        }
      </script>
      <ins class="adsbygoogle"
           style="display:block; min-height:280px; margin:10px 0;"
           data-ad-client="ca-pub-7116765104034349"
           data-ad-slot="1108362298"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  `;

  const getAd = () => {
    if (contentType === "google_ads") {
      return googleDisplayAd;
    } else if (contentType === "tenping") {
      const randomIndex = Math.floor(Math.random() * tenpingAds.length);
      return generateAdHtml(tenpingAds[randomIndex]);
    }
    // 광고 로드 실패 시 tenping 광고 반환
    const randomIndex = Math.floor(Math.random() * tenpingAds.length);
    return generateAdHtml(tenpingAds[randomIndex]);
  };

  const tenping = getAd();

  const adsense =
    '<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7116765104034349" crossorigin="anonymous"></script><ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-7116765104034349" data-ad-slot="7465915067" data-ad-format="auto" data-full-width-responsive="true"></ins><script>(adsbygoogle = window.adsbygoogle || []).push({});</script>';
  const query = queryString.parse(location.search);
  const [keyword, setKeyword] = useState(query["keyword"]);
  const { register, handleSubmit } = useForm();
  const [regionCheck, setregionCheck] = useState({
    checked: false,
  });
  const [price, setPrice] = useState("가격순");

  const { result, loading } = FindProduct(keyword, regionCheck);
  const [data, setData] = useState([]);
  const [fashionProducts, setFashionProducts] = useState([]);

  useEffect(() => {
    if (price == "가격순") {
      result.sort(function (x, y) {
        return new Date(y.writeTime) - new Date(x.writeTime);
      });
      setData(result);
    } else {
      result.sort(function (x, y) {
        return x.price - y.price;
      });
      setData(result);
    }
  }, [keyword, result]);
  const handleRegionChange = (e) => {
    setregionCheck({
      ...regionCheck,
      [e.target.name]: e.target.checked,
    });
  };

  const handlePriceClick = (e) => {
    // e.persist();
    if (e.target.innerText == "최신순") {
      setPrice("가격순");
      result.sort(function (x, y) {
        return new Date(y.writeTime) - new Date(x.writeTime);
      });
      setData([...result]);
    } else {
      setPrice("최신순");
      result.sort(function (x, y) {
        return x.price - y.price;
      });
      setData([...result]);
    }
  };

  const onSubmit = (data) => console.log(data);

  const template = {
    objectType: "feed",
    content: {
      title: `가성비 좋은 상품을 찾고 계신가요? - 중고마켓`,
      description: `새상품과 중고상품을 한 번에 비교하자!`,
      imageUrl: window.location.href + "/kakaologo.png",
      link: {
        mobileWebUrl: window.location.href,
        webUrl: window.location.href,
      },
    },
    social: {
      likeCount: 7491,
      commentCount: 713,
      sharedCount: 9158,
    },
    buttons: [
      {
        title: "웹으로 보기",
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      {
        title: "앱으로 보기",
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
    ],
  };
  // console.log(loading);
  // console.log(data);
  // console.log(keyword);

  useEffect(() => {
    document.title = "중고마켓 - 좋은 상품만";
  }, []);

  useEffect(() => {
    const getFashionItems = async () => {
      if (!keyword && showFashion) {
        try {
          const {
            data: { body: data },
          } = await CoupangApi.getFashionProducts();
          if (data) {
            // 가격 50,000원 이하, 리뷰 200개 이상인 상품만 필터링
            const filteredData = data.filter(product => 
              product.price <= 50000 && 
              product.ratingCount >= 100
            );
            setFashionProducts(filteredData);
          }
        } catch (err) {
          console.error(err);
        }
      }
    };
    getFashionItems();
  }, [showFashion, keyword]);

  useEffect(() => {
    // 구글 광고 초기화
    if (!showFashion) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (err) {
        console.error('Google ads error:', err);
      }
    }
  }, [showFashion]);

  if (loading) {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://joongo.funfun-man.com">
              <HeaderImg
                src="/logo.png"
                onload="window.DD_RUM.addTiming('log_image')"
              />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} onSubmit={onSubmit} />
              <Link>
                <A
                  href="https://play.google.com/store/apps/details?id=info.goldkeyword.joongomarket"
                  styled={{}}
                >
                  <APP>APP 다운</APP>
                </A>
                <KakaoLinkDefault
                  className="template"
                  template={template}
                  jsKey={"70f9211c78cf0fcc45b49ebe77dc9889"}
                >
                  <SNS>공유하기</SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>판매중인 중고상품과 평점 좋은 새상품을 한 번에 비교하세요!</TXT>
        </Fixed>
        <Loading />
      </Container>
    );
  } else if (loading == false && data.length > 0 && keyword) {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://joongo.funfun-man.com">
              <HeaderImg
                src="/logo.png"
                onload="window.DD_RUM.addTiming('log_image')"
              />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} onSubmit={onSubmit} />
              <Link>
                <A
                  href="https://play.google.com/store/apps/details?id=info.goldkeyword.joongomarket"
                  styled={{}}
                >
                  <APP>APP 다운</APP>
                </A>
                <KakaoLinkDefault
                  className="template"
                  template={template}
                  jsKey={"70f9211c78cf0fcc45b49ebe77dc9889"}
                >
                  <SNS>공유하기</SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>판매중인 중고상품과 평점 좋은 새상품을 한 번에 비교하세요!</TXT>
          <SUBTXTContainer>
            <SUBTXT style={{ marginTop: "1.3%" }}>
              - 모든 지역
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={regionCheck.checked}
                    onChange={handleRegionChange}
                    name="checked"
                    styled={{ marginRight: "0px" }}
                  />
                }
              />
              위치 정보 기반
              <Button
                variant="contained"
                color="primary"
                size="medium"
                price={price}
                onClick={handlePriceClick}
                style={{
                  float: "right",
                  backgroundColor: "#00792f",
                }}
              >
                {price}
              </Button>
            </SUBTXT>
          </SUBTXTContainer>
        </Fixed>
        <JoongoContainer>
          {data.map((key, index) => {
            if (key.type == "used") {
              return (
                <Joongo
                  key={index}
                  type={key.originType}
                  header={key.header}
                  imgSrc={key.thumnail}
                  pcLink={key.pcLink}
                  mobileLink={key.mobileLink}
                  title={key.title}
                  price={key.price}
                  deliveryType={key.deliveryType}
                  condition={key.condition}
                  region={key.region}
                  writeTime={key.writeTime}
                />
              );
            } else if (key.ratingCount != 0) {
              return (
                <Product
                  key={index}
                  linkUrl={key.linkUrl}
                  type={"[🎁평점 좋은 새상품]"}
                  header={key.header}
                  imgSrc={key.imgSrc}
                  title={key.title}
                  price={key.price}
                  promise={key.promise}
                  rating={key.rating}
                  ratingCount={key.ratingCount}
                />
              );
            }
          })}
          {/* {ListTemplate} */}
        </JoongoContainer>

        <CheckFooter result={result} keyword={keyword} />
      </Container>
    );
  } else if (loading == false && !keyword) {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://joongo.funfun-man.com">
              <HeaderImg
                src="/logo.png"
                onload="window.DD_RUM.addTiming('log_image')"
              />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} />
              <Link>
                <A href="https://play.google.com/store/apps/details?id=info.goldkeyword.joongomarket">
                  <APP>APP 다운</APP>
                </A>
                <KakaoLinkDefault
                  className="template"
                  template={template}
                  jsKey={"70f9211c78cf0fcc45b49ebe77dc9889"}
                >
                  <SNS>공유하기</SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>판매중인 중고상품과 평점 좋은 새상품을 한 번에 비교하세요!</TXT>
        </Fixed>

        {showFashion && fashionProducts.length > 0 ? (
          <JoongoContainer>
            {fashionProducts.map((product, index) => (
              <Product
                key={index}
                linkUrl={product.linkUrl}
                type={"[🎁평점 좋은 새상품]"}
                header={product.header || ""}
                imgSrc={product.imgSrc}
                title={"중고마켓 - 좋은 상품만"}
                price={product.price || 0}
                promise={product.promise}
                rating={product.rating}
                ratingCount={product.ratingCount}
              />
            ))}
          </JoongoContainer>
        ) : (
          <>
            <MobileView>
              <CoupangContainer>
                {/* 구글 광고 */}
                <div dangerouslySetInnerHTML={{ 
                  __html: `
                    <!-- 중고마켓 -->
                    <ins class="adsbygoogle"
                      style="display:block"
                      data-ad-client="ca-pub-7116765104034349"
                      data-ad-slot="7465915067"
                      data-ad-format="auto"
                      data-full-width-responsive="true"></ins>
                    <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                  `
                }} />
                {/* tenping 광고 */}
                <div style={{ marginTop: '3px' }}>
                  <div dangerouslySetInnerHTML={{ __html: generateAdHtml(tenpingAds[0]) }} />
                </div>
              </CoupangContainer>
            </MobileView>
            <BrowserView>
              <CoupangContainer>
                <CoupangInside>
                  {/* 구글 광고 */}
                  <div dangerouslySetInnerHTML={{ 
                    __html: `
                      <!-- 중고마켓 -->
                      <ins class="adsbygoogle"
                        style="display:block"
                        data-ad-client="ca-pub-7116765104034349"
                        data-ad-slot="7465915067"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                      <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                      </script>
                    `
                  }} />
                  {/* tenping 광고 */}
                  <div style={{ marginTop: '3px' }}>
                    <div dangerouslySetInnerHTML={{ __html: generateAdHtml(tenpingAds[0]) }} />
                  </div>
                </CoupangInside>
              </CoupangContainer>
            </BrowserView>
          </>
        )}
        <CheckFooter result={showFashion ? fashionProducts : result} keyword={keyword} />
      </Container>
    );
  } else {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://joongomarket.com">
              <HeaderImg
                src="/logo.png"
                onload="window.DD_RUM.addTiming('log_image')"
              />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} onSubmit={onSubmit} />
              <Link>
                <A
                  href="https://play.google.com/store/apps/details?id=info.goldkeyword.joongomarket"
                  styled={{}}
                >
                  <APP>APP 다운</APP>
                </A>
                <KakaoLinkDefault
                  className="template"
                  template={template}
                  jsKey={"70f9211c78cf0fcc45b49ebe77dc9889"}
                >
                  <SNS>공유하기</SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>판매중인 중고상품과 평점 좋은 새상품을 한 번에 비교하세요!</TXT>
        </Fixed>
        <CheckFooter result={result} keyword={keyword} />
      </Container>
    );
  }
};

export default Main;
