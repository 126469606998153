import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const AdContainer = styled.div`
  min-height: 100px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisplayAds = () => {
  const adRef = useRef(null);

  useEffect(() => {
    try {
      // 기존 광고 스크립트 제거
      const existingScript = document.querySelector('script[src*="pagead2.googlesyndication.com"]');
      if (existingScript) {
        existingScript.remove();
      }

      // 새로운 광고 스크립트 추가
      const adScript = document.createElement('script');
      adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7116765104034349";
      adScript.async = true;
      adScript.crossOrigin = "anonymous";
      document.head.appendChild(adScript);

      // 광고 로드
      adScript.onload = () => {
        try {
          if (adRef.current && window.adsbygoogle) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        } catch (e) {
          console.error('Ad push error:', e);
        }
      };

      return () => {
        // 컴포넌트 언마운트 시 스크립트 제거
        if (adScript.parentNode) {
          adScript.parentNode.removeChild(adScript);
        }
      };
    } catch (e) {
      console.error('Ad script error:', e);
    }
  }, []);

  return (
    <AdContainer>
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{
          display: "block",
          minHeight: "100px",
          width: "100%",
        }}
        data-ad-client="ca-pub-7116765104034349"
        data-ad-slot="7465915067"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </AdContainer>
  );
};

export default DisplayAds;
