import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

const Footer = styled.div`
  width: 100%;
  font-size: 1.5vh;
  float: left;
  padding: 1vh 0 1vh 0;
  background-color: #eff2f8;
  color: #868b96;
`;
const Blank = styled.div`
  margin-top: 160px;
  background-color: #dddddd;
  padding-top: 23vh;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  margin-top: -5vh;
  font-weight: bold;
  @media only screen and (max-width: 750px) {
    font-size: 15px;
  }
`;
const NotFound = styled.div`
  background-color: #dddddd;
  padding-top: 23vh;
  height: 80vh;
  display: flex;
  margin-top: -5vh;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: bold;
  @media only screen and (max-width: 750px) {
    font-size: 15px;
  }
`;

const CheckFooter = ({ result, keyword }) => {
  if (result.length != 0 && result[0] != "NotFound" && result[0] != false && keyword) {
    return (
      <>
        <Helmet>
          <title>{`${keyword} 중고 가격, 평점, 리뷰, 내돈내산 후기 검색결과`}</title>
        </Helmet>
        <Footer>
          COPYRIGHT © 2021 중고마켓 <br></br>
          {/* 플랫폼 문의:{" "}
          <a href="master@gold-keyword.info">master@gold-keyword.info </a>
          <br></br> */}
          중고마켓은 통신판매의 당사자가 아닌 통신판매중개자로서
          상품/상품정보/거래에 대한 책임이 없으므로 상세 페이지를 통해 구체적인
          상품 정보를 확인하시기 바랍니다.<br></br>
          쇼핑몰 링크를 통해 구매한 상품의 가격은 동일 하지만 운영 비용을 위해
          일부 쇼핑몰들로부터 일정 커미션을 받을 수 있습니다.
          <br></br>
        </Footer>
      </>
    );
  } else if (result.length == 0 && keyword) {
    return (
      <>
        <Helmet>
          <title>{`${keyword} 중고 가격, 평점, 리뷰, 내돈내산 후기 검색결과`}</title>
        </Helmet>
        <NotFound>
          {`아쉽게도 ${keyword} 상품을 찾을 수 없네요. 🙏`}
          <br></br>
          <br></br>
          {`계속 상품을 찾을 수 없는 경우(프로그램 문제 발생),`}
          <br></br>
          {`master@gold-keyword.info로 메일 주시면 빠른 시간내에 고치도록 하겠습니다. `}
        </NotFound>
        <Footer>
          COPYRIGHT © 2021 중고마켓 <br></br>
          {/* 플랫폼 문의:{" "}
          <a href="master@gold-keyword.info">master@gold-keyword.info </a>
          <br></br> */}
          중고마켓은 통신판매의 당사자가 아닌 통신판매중개자로서
          상품/상품정보/거래에 대한 책임이 없으므로 상세 페이지를 통해 구체적인
          상품 정보를 확인하시기 바랍니다.<br></br>
          쇼핑몰 링크를 통해 구매한 상품의 가격은 동일 하지만 운영 비용을 위해
          일부 쇼핑몰들로부터 일정 커미션을 받을 수 있습니다.
          <br></br>
        </Footer>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>중고마켓 - 좋은 상품만</title>
        </Helmet>
        <Footer>
          COPYRIGHT © 2021 중고마켓<br></br>
          {/* 플랫폼 문의:{" "}
          <a href="master@gold-keyword.info">master@gold-keyword.info </a>
          <br></br> */}
          중고마켓은 통신판매의 당사자가 아닌 통신판매중개자로서
          상품/상품정보/거래에 대한 책임이 없으므로 상세 페이지를 통해 구체적인
          상품 정보를 확인하시기 바랍니다.<br></br>
          쇼핑몰 링크를 통해 구매한 상품의 가격은 동일 하지만 운영 비용을 위해
          일부 쇼핑몰들로부터 일정 커미션을 받을 수 있습니다.
          <br></br>
        </Footer>
      </>
    );
  }
};

export default CheckFooter;
